import React from 'react';

import { useFetch } from '../../hooks';
// import useFetch from '../../__mocks__/useFetch/useFetch';
import { Widget } from '../../components/layout/widget';
import Count from './Members.styles';
import Member from './member/member';
// import MembersHeader from './members-header/MembersHeader';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Members = () => {
    // eslint-disable-next-line prefer-const
    let [data, error, loading] = useFetch(`${BASE_URL}/members`);
    const noContentMessage = `Looks like there are no members on campus today!`;
    const errorMessage = `Oops, something went wrong 😞`;

    let members = [];
    if (data && data.members) members = data.members;

    return (
        <Widget>
            <Widget.Header flex='space-between'>
                <h2 data-testid='members-title'>Campus Members</h2>
                <Count data-testid='members-count'>
                    {`${members.length} member${
                        members.length !== 1 ? 's' : ''
                    }`}
                </Count>
            </Widget.Header>
            <Widget.Body
                error={error}
                loading={loading}
                noContent={members.length === 0}
            >
                <Widget.Body.ErrorMessage>
                    {errorMessage}
                </Widget.Body.ErrorMessage>
                <Widget.Body.NoContentMessage>
                    {noContentMessage}
                </Widget.Body.NoContentMessage>
                <Widget.Body.Loading />
                <Widget.Body.Content>
                    {members
                        .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
                        .map(({ firstName, lastName, role, cardId }) => {
                            const name = `${firstName} ${lastName}`;
                            return (
                                <Member key={cardId} name={name} job={role} />
                            );
                        })}
                </Widget.Body.Content>
            </Widget.Body>
        </Widget>
    );
};

export default Members;

// {
//     // error && !loading && <Message>{errorMsg}</Message>
// }
// {
//     //showNoMembers && (
//         //<WidgetBody.Content>{noMembers}</WidgetBody.Content>)
// }
