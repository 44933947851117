import styled from 'styled-components';

const Body = styled.div`
    flex-grow: 1;
    padding: 0.25rem 0.5rem;
    background-color: #f9f8f8;
    overflow: hidden;

    @media (min-width: 2050px) {
        padding: 0.75rem 1.255rem;
        font-size: x-large;
    }
`;

export default Body;
