import React from 'react';
import { Container, Text } from './Message.styles';

const Message = ({ children }) => {
    return (
        <Container>
            <Text data-testid='message-text'>{children}</Text>
        </Container>
    );
};

export default Message;
