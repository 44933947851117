import { css } from 'styled-components';

const sizes = {
    xxxl: 1600,
    xxl: 1400,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576
};

export default Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (min-width: ${sizes[label]}px) {
            ${css(...args)};
        }
    `;
    return acc;
}, {});
