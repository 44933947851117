import styled, { css } from 'styled-components';
import media from '../../../../styles/media';
import { darkTheme } from '../../../../styles/theme';

// const handleHeaderBgColor = colorScheme => {
//     switch (colorScheme) {
//         case 'primary':
//             return 'var(--primary-color)';
//         case 'secondary':
//             return 'var(--secondary-color)';
//         case 'tertiary':
//             return 'var(--tertiary-color)';
//         case 'quaternary':
//             return 'var(--quaternary-color)';
//         case 'quinary':
//             return 'var(--quinary-color)';
//         case 'senary':
//             return 'var(--senary-color)';
//         default:
//             return 'var(--primary-color)';
//     }
// };

// const handleHeaderColor = colorScheme => {
//     switch (colorScheme) {
//         case 'primary':
//             return 'var(--text-secondary-color)';
//         case 'secondary':
//             return 'var(--text-color)';
//         case 'tertiary':
//             return 'var(--text-color)';
//         case 'quaternary':
//             return 'var(--text-secondary-color)';
//         case 'quinary':
//             return 'var(--text-secondary-color)';
//         case 'senary':
//             return 'var(--text-color)';
//         default:
//             return 'var(--text-secondary-color)';
//     }
// };

const Header = styled.header`
    background-color: #ffc800;
    color: black;
    font-family: 'Brown Regular', Arial, Helvetica, sans-serif;
    font-size: 1.25rem;
    padding: 0.2% 1%;
    display: flex;
    justify-content: ${props => props.flex};
    align-items: center;

    & h1,
    h2 {
        font-size: inherit;
    }

    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 p {
        color: inherit;
    }

    ${({ theme }) =>
        theme.name === darkTheme.name &&
        css`
            background-color: ${theme.secondBody};
            color: ${theme.text};
        `}

    ${media.xxxl`
        font-size: 1.5625rem;
    `}

    @media (min-width: 2050px) {
        padding: 0.5% 1%;
    }
`;

export default Header;
