import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import Body from './WidgetBody.styles';
import Message from '../../message/Message';

export const ErrorMessage = ({ error, children }) =>
    error ? <Message>{children}</Message> : null;

export const NoContentMessage = ({ error, loading, noContent, children }) =>
    !error && !loading && noContent ? <Message>{children}</Message> : null;

export const Loading = ({ loading }) =>
    loading ? (
        <div data-testid='loader'>
            <Message>
                <Loader type='Plane' color='#323232' height={80} width={80} />
            </Message>
        </div>
    ) : null;

export const Content = ({ error, loading, children }) =>
    !error && !loading ? <React.Fragment>{children}</React.Fragment> : null;

class WidgetBody extends React.Component {
    static ErrorMessage = ErrorMessage;

    static NoContentMessage = NoContentMessage;

    static Loading = Loading;

    static Content = Content;

    render() {
        const { error, loading, noContent, children } = this.props;
        return (
            <Body>
                {React.Children.map(children, childElement => {
                    return React.cloneElement(childElement, {
                        error,
                        loading,
                        noContent
                    });
                })}
            </Body>
        );
    }
}

WidgetBody.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool,
    noContent: PropTypes.bool
};

WidgetBody.defaultProps = {
    error: false,
    noContent: false
};

export default WidgetBody;
