import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styles from '../dashboard/Dashboard.module.scss';

const TitleBar = () => {
    const [time, setTime] = useState(moment());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTime(moment());
        }, 200);
        return () => {
            clearTimeout(timer);
        };
    }, [time]);
    return (
        <React.Fragment>
            <span className={styles.TitleBar__Date}>
                {time.format('H:mm - ddd Do MMMM')}
            </span>
            <h1 className={styles.TitleBar__Title}>Ada Dashboard</h1>
            <span className={styles.TitleBar__Toggle} />
        </React.Fragment>
    );
};

export default TitleBar;
