export const lightTheme = {
    name: 'lightTheme',
    body: 'var(--light-bg-color)',
    widgetBg: '#F9F8F8',
    text: 'var(--text-color)',
    containerBorder: 'transparent'
};

export const darkTheme = {
    name: 'darkTheme',
    body: '#363537',
    widgetBg: '#363437',
    text: 'var(--text-secondary-color)',
    containerBorder: '#e8eaed',
    secondBody: '#3c3c3c'
};
