import { useEffect, useState } from 'react';

const useFetch = (url, options) => {
    /* 
    The useFetch hook is generic. 
    On render returns undefined instead default data type. 
    It can be different in each request
    */

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(false);

            try {
                const response = await fetch(url, {
                    ...options
                });
                const json = await response.json();
                setData(json);
            } catch {
                setError(true);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [url, options]);

    return [data, error, loading];
};

export default useFetch;
