import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MemberWrapper = styled.article`
    display: inline-flex;
    justify-content: space-between;
    align-items: baseline;
    flex: 1;
    width: 46%;
    margin: 0.2% 2%;
    padding: 0 0.3rem;
    font-size: small;
    /* background-color: #e6e6e6; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

    @media (min-width: 2050px) {
        padding: 0.5% 2%;
        font-size: x-large;
    }

    @media (min-width: 3000px) {
        padding: 1% 2%;
    }
`;

const Member = ({ name, job }) => {
    name = name.replace(/\[/g, '');
    name = name.replace(/\]/g, '');

    job = job.substring(0, 2);

    return (
        <MemberWrapper data-testid='member-card'>
            <span>{name}</span>
            <span>{job.toUpperCase()}</span>
        </MemberWrapper>
    );
};

Member.propTypes = {
    name: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired
};

export default Member;
