import React from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './styles/theme';
import { GlobalStyles } from './styles/global';
import Dashboard from './containers/dashboard/Dashboard';
import { useDarkMode } from './hooks';

import './styles/index.scss';

const App = () => {
    const [theme, toggleTheme, componentMounted] = useDarkMode();
    const currentTheme = theme === 'light' ? lightTheme : darkTheme;

    if (!componentMounted) {
        return <div />;
    }

    return (
        <ThemeProvider theme={currentTheme}>
            <GlobalStyles />
            <Dashboard themeMode={theme} toggleTheme={toggleTheme} />
        </ThemeProvider>
    );
};

export default App;
