import React from 'react';
import styles from './Dashboard.module.scss';
import {
    Members,
    TitleBar,
    CampusCalendar,
    KeyContacts,
    ImportantNotices
} from '..';
import CampusEvents from '../campusEvents/CampusEvents';
import Projects from '../projects/Projects';

const Dashboard = () => {
    // When Component is created:
    //      - Replace template text below so the component is
    //              embedded in the section
    //      - Remove the background colour from the SCSS file.

    return (
        <div className={styles.Dashboard}>
            <section className={styles.TitleBar}>
                <TitleBar />
            </section>
            <section className={styles.Members}>
                <Members />
            </section>
            <section className={styles.CampusCalendar}>
                <CampusCalendar />
            </section>
            <section className={styles.CampusEvents}>
                <CampusEvents />
            </section>
            <section className={styles.KeyContacts}>
                <KeyContacts />
            </section>
            <section className={styles.ImportantNotices}>
                <ImportantNotices />
            </section>
            <section className={styles.Projects}>
                <Projects />
            </section>
        </div>
    );
};

export default Dashboard;
