import React from 'react';
import { Widget } from '../../components/layout/widget';

const ImportantNotices = () => {
    const noContentMessage = `Not yet connected!`;
    const errorMessage = `Oops, something went wrong 😞`;

    return (
        <Widget>
            <Widget.Header flex='space-around'>
                <h2 data-testid='important-notices-title'>Important Notices</h2>
            </Widget.Header>
            <Widget.Body error={false} loading={false} noContent>
                <Widget.Body.ErrorMessage>
                    {errorMessage}
                </Widget.Body.ErrorMessage>
                <Widget.Body.NoContentMessage>
                    {noContentMessage}
                </Widget.Body.NoContentMessage>
                <Widget.Body.Loading />
                <Widget.Body.Content></Widget.Body.Content>
            </Widget.Body>
        </Widget>
    );
};

export default ImportantNotices;
