import { createGlobalStyle } from 'styled-components';
import BrownRegular from '../fonts/brown-regular.ttf';
import BrownBold from '../fonts/brown-bold.ttf';

export const GlobalStyles = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap');

    @font-face {
        font-family: 'Brown Regular';
        src: local('Brown Regular'), local('BrownRegular'), url("${BrownRegular}");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Brown Bold';
        src: local('Brown Bold'), local('BrownBold'), url("${BrownBold}");
        font-weight: 700;
        font-style: normal;
    }
    
    *,
    *::after,
    *::before {
        box-sizing: border-box;
    }

    :root {
        --primary-color: var(--AND-RED);
        --secondary-color: var(--AND-YELLOW);
        --tertiary-color: var(--AND-GREEN);
        --quaternary-color: var(--AND-BLUE);
        --quinary-color: var(--AND-PURPLE);
        --senary-color: var(--AND-PINK);
        --alt-color: var(--AND-CHARCOAL);
        --text-color: var(--AND-CHARCOAL);
        --text-secondary-color: var(--AND-WHITE);
        --light-bg-color: var(--AND-WHITE);
        --dark-bg-color: var(--AND-CHARCOAL);
    
        --AND-RED: #fe323c;
        --AND-YELLOW: #ffc800;
        --AND-GREEN: #5ac328;
        --AND-BLUE: #2897ff;
        --AND-PURPLE: #a050ff;
        --AND-PINK: #ff6ecf;
        --AND-CHARCOAL: #323232;
        --AND-WHITE: #ffffff;
    }

    body {
        background: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-size: 16px;
    }

    h1, h2, h3, h4, h5, h6  {
        margin-top: 0;
        margin-bottom: 0;
        color: ${({ theme }) => theme.text};
        font-weight: 500;
    }
`;

export default GlobalStyles;
