import React from 'react';
// import Wrapper from './Widget.styles';
import { WidgetHeader } from './header';
import { WidgetBody } from './body';

class Widget extends React.Component {
    static Header = WidgetHeader;

    static Body = WidgetBody;

    render() {
        const { children } = this.props;
        // return <Wrapper>{children}</Wrapper>;
        return React.Children.map(children, child => child);
    }
}

export default Widget;
