import React from 'react';
import Header from './Header.styles';

const WidgetHeader = ({
    flex = 'space-around',
    children,
    colorScheme = 'primary'
}) => {
    return (
        <Header
            flex={flex}
            colorScheme={colorScheme}
            data-testid='widget-header'
        >
            {children}
        </Header>
    );
};

export default WidgetHeader;
